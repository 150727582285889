import { Route, Routes } from "react-router-dom";
import AboutPage from "./components/Pages/AboutPage";
import BlogPage from "./components/Pages/BlogPage";
import BlogDetailsPage from "./components/Pages/BlogDetailsPage";
import ContactPage from "./components/Pages/ContactPage";
import ErrorPage from "./components/Pages/ErrorPage";
import PortfolioDetailsPage from "./components/Pages/PortfolioDetailsPage";
import ServiceDetailsPage from "./components/Pages/ServiceDetailsPage";
import ServicesPage from "./components/Pages/ServicesPage";
import TeamPage from "./components/Pages/TeamPage";
import PortfolioPage from "./components/Pages/PortfolioPage";
import TeamDetails from "./components/Pages/TeamDetails";
import PhotographyAgencyHome from "./components/Pages/PhotographyAgencyHome";
import CreativePortfolioHome from "./components/Pages/CreativePortfolioHome";
import DigitalAgencyHome from "./components/Pages/DigitalAgencyHome";
import MarketingAgencyHome from "./components/Pages/MarketingAgencyHome";
import ShowcasePortfolioHome from "./components/Pages/ShowcasePortfolioHome";
import CaseStudyShowcaseHome from "./components/Pages/CaseStudyShowcaseHome";
import Layout from "./components/Layout";
import CaseStudyDetailsPage from "./components/Pages/CaseStudyDetailsPage";
import FaqPage from "./components/Pages/FaqPage";
import { useEffect, useState } from "react";

function App() {
  const [gameRef, setGameRef] = useState(null);
  // const [visionRef, setVisionRef] = useState(null);
  // const [membersRef, setMembersRef] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const unusedRoutes = [
    { path: "service", component: <ServicesPage /> },
    { path: "service/:serviceDetailsId", component: <ServiceDetailsPage /> },
    { path: "portfolio", component: <PortfolioPage /> },
    {
      path: "portfolio/:portfolioDetailsId",
      component: <PortfolioDetailsPage />,
    },
    { path: "blog", component: <BlogPage /> },
    { path: "blog/:blogDetailsId", component: <BlogDetailsPage /> },

    { path: "about", component: <AboutPage /> },
    { path: "contact", component: <ContactPage /> },

    { path: "team", component: <TeamPage /> },
    { path: "team/:teamDetails", component: <TeamDetails /> },

    {
      path: "/case-study/:caseStudyDetailsId",
      component: <CaseStudyDetailsPage />,
    },
    { path: "faq", component: <FaqPage /> },
  ];
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <Layout
              headerVariant="cs-site_header_full_width"
              gameRef={gameRef}
              // visionRef={visionRef}
              // membersRef={membersRef}
            />
          }
        >
          <Route
            index
            element={
              <CreativePortfolioHome
                setGameRef={setGameRef}
                // setVisionRef={setVisionRef}
                // setMembersRef={setMembersRef}
              />
            }
          />
          <Route path="contact" element={<ContactPage />} />
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
}

export default App;
