import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import CustomCursor from "../CustomCursor";
import Footer from "../Footer";
import Header from "../Header";
import "../../mb-assets/scss/common.scss";

export default function Layout({
  headerVariant,
  gameRef,
  // visionRef,
  // membersRef,
}) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header
        variant={headerVariant}
        gameRef={gameRef}
        // visionRef={visionRef}
        // membersRef={membersRef}
      />
      <Outlet />
      {/* <CustomCursor /> */}
      <Footer />
    </>
  );
}
