import React, { useEffect, useRef } from "react";
import CaseStudy from "../CaseStudy";
import Cta from "../Cta";
import Div from "../Div";
import Hero3 from "../Hero/Hero3";
import LogoList from "../LogoList";
import MovingText from "../MovingText";
import Portfolio2 from "../Portfolio/Portfolio2";
import Portfolio3 from "../Portfolio/Portfolio3";
import OurVision from "../Portfolio/ourVision";
import SectionHeading from "../SectionHeading";
import TestimonialSlider from "../Slider/TestimonialSlider";
import Spacing from "../Spacing";
import { pageTitle } from "../../helper";
import video from "../../mb-assets/img/video/main.mp4";
import imgAboutUs from "../../mb-assets/img/bg/about-us.png";
import CoreMembers from "../Portfolio/coreMembers";
import main01 from "../../mb-assets/img/bg/main01.png";
import main02 from "../../mb-assets/img/bg/main02.png";
import main03 from "../../mb-assets/img/bg/main03.png";

export default function CreativePortfolioHome({ setGameRef }) {
  pageTitle("Creative Portfolio");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const game = useRef(null);

  useEffect(() => {
    if (game) {
      setGameRef(game.current);
    }
  }, [game]);

  const heroSocialLinks = [
    {
      name: "Discord",
      links: "/",
    },
    {
      name: "Twitter",
      links: "/",
    },
    {
      name: "Facebook",
      links: "/",
    },
    {
      name: "Medium",
      links: "/",
    },
  ];
  const portfolioData = [
    {
      txt: "With a rich background in game development and a track record of successful startup game businesses, our team brings a wealth of experience to the table. We are driven by a shared passion for creating captivating games that immerse players in extraordinary worlds.",
      imageUrl: main01,
      whiteFont: "OUR",
      orangeFont: "TEAM MAKEUP",
    },
    {
      txt: "At our core, we are driven by a curiosity for new technologies and a passion for pushing boundaries. We believe in the power of collaboration and actively involve the community in shaping our games. Our ultimate vision is to create games that stand the test of time, offering deep gameplay experiences and endless enjoyment.",
      imageUrl: main02,
      whiteFont: "WHAT",
      orangeFont: "WE DO",
    },
    {
      txt: "Galaxy Commanders offers epic real-time space battles set against a sci-fi universe. With a myriad of distinctive battleships and units at your disposal, customize your own fleet and participate in exhilarating space battles. Visit our website and community to stay up-to-date with the latest updates and exciting events.",
      imageUrl: main03,
      whiteFont: "",
      orangeFont: "",
    },
  ];
  return (
    <>
      <Hero3
        title="Arino Creative <br />Portfolio"
        btnLink="contact"
        btnText={`Let's talk`}
        socialLinksHeading="Follow Us"
        heroSocialLinks={heroSocialLinks}
        bgImageUrl={video}
      />
      {portfolioData.map(
        (item, index) => (
          <Div key={index}>
            {index === 2 && <div style={{ height: "0px" }} ref={game}></div>}
            <Spacing lg="150" md="80" />
            <Portfolio2
              txt={item.txt}
              imageUrl={item.imageUrl}
              whiteFont={item.whiteFont}
              orangeFont={item.orangeFont}
              reverse={index % 2 === 0 ? false : true}
              line={index === 0 ? true : false}
              official={index === 2 ? true : false}
            />

            {/* <Spacing lg="100" md="70" /> */}
            {/* <div style={{ height: "0px" }} ref={vision}></div>
            <OurVision /> */}
            {/* <div style={{ height: "0px" }} ref={members}></div>
              <CoreMembers /> */}
          </Div>
        )
        // <Div key={index}>
        //   <div style={{ height: "0px" }} ref={game}></div>
        //   <Portfolio3
        //     title={item.title}
        //     subtitle={item.subtitle}
        //     btnText={item.btnText}
        //     btnLink={item.btnLink}
        //     imageUrl={item.imageUrl}
        //     category={item.category}
        //   />
        // </Div>
      )}
    </>
  );
}
