import React from "react";
import parse from "html-react-parser";
import WaterWave from "react-water-wave";
import Div from "../Div";
import Button from "../Button";
import VerticalLinks from "../VerticalLinks";
import "./hero.scss";
import arrow from "../../mb-assets/img/icon/arrow-right.png";

export default function Hero3({
  title,
  socialLinksHeading,
  heroSocialLinks,
  btnLink,
  btnText,
  bgImageUrl,
}) {
  return (
    <Div className="cs-hero cs-style1 cs-type2" id="home">
      <div className="official-vid img-wrapper">
        <video src={bgImageUrl} playsInline autoPlay muted loop />
        {/* <div className="ab-title">
          <p className="top-title">TAKE THE GAME INDUSTRY BY STORM</p>
          <p className="bottom-title">WITH THE PASSION THAT BOILS LIKE LAVA!</p>
          <div
            className="to-official"
            onClick={() => {
              window.open("https://www.galaxycommanders.io/");
            }}
          >
            <div className="img-wrapper">
              <img src={arrow} alt="" />
            </div>
            {window.innerWidth > 380 ? (
              <p>GALAXY COMMANDERS OFFICIAL SITE</p>
            ) : (
              <p>
                GALAXY COMMANDERS <br /> OFFICIAL SITE
              </p>
            )}
          </div>
        </div> */}
      </div>
      {/* 
      <VerticalLinks
        data={heroSocialLinks}
        title={socialLinksHeading}
        variant="cs-left_side"
      /> */}
    </Div>
  );
}
