import React from "react";
import Div from "../Div";
import ContactInfoWidget from "../Widget/ContactInfoWidget";
import MenuWidget from "../Widget/MenuWidget";
import Newsletter from "../Widget/Newsletter";
import SocialWidget from "../Widget/SocialWidget";
import TextWidget from "../Widget/TextWidget";
import footerLogo from "../../mb-assets/img/logo/footer-logo.png";
import "./footer.scss";

export default function Footer({ copyrightText, logoSrc, logoAlt, text }) {
  const copyrightLinks = [
    // {
    //   title: "CONTACT US",
    //   href: "/",
    // },
    // {
    //   title: "PRIVACY POLICY",
    //   href: "/",
    // },
    // {
    //   title: "SITE POLICY",
    //   href: "/",
    // },
  ];

  const serviceMenu = [
    {
      title: "UI/UX design",
      href: "/service/service-details",
    },
    {
      title: "WP development",
      href: "/service/service-details",
    },
    {
      title: "Digital marketing",
      href: "/service/service-details",
    },
    {
      title: "React development",
      href: "/service/service-details",
    },
  ];

  return (
    <footer className="cs-fooer">
      <Div className="cs-fooer_main">
        <Div className="container">
          <Div className="row">
            <Div className="col-lg-3 col-sm-12 f-st">
              {/* <Div className="cs-footer_item ">
                <TextWidget logoSrc={footerLogo} logoAlt="Logo" text="" />
              </Div> */}
            </Div>
          </Div>
        </Div>
      </Div>
      <Div className="container">
        <Div className="cs-bottom_footer">
          <Div className="cs-bottom_footer_left">
            <div className="img-wrapper">
              <img src={footerLogo} alt="" />
            </div>
          </Div>
          <Div className="cs-bottom_footer_right">
            {/* <MenuWidget menuItems={copyrightLinks} variant="cs-style2" /> */}
            <div className="copy-r">help@magmabyte.io</div>
            <div className="copy-r">@ 2023 MagmaByte. All rights reserved.</div>
          </Div>
        </Div>
      </Div>
    </footer>
  );
}
