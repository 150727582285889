import React, { useEffect, useState, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import SocialWidget from "../Widget/SocialWidget";
import Newsletter from "../Widget/Newsletter";
import "./header.scss";
import ContactInfoWidget from "../Widget/ContactInfoWidget";
import Div from "../Div";
import logo from "../../mb-assets/img/logo/main-logo.png";

export default function Header({ variant, gameRef, visionRef }) {
  const [isSticky, setIsSticky] = useState(false);
  const [nevigatgion, setNavigation] = useState(null);

  const [sideHeaderToggle, setSideHeaderToggle] = useState(false);
  const [mobileToggle, setMobileToggle] = useState(false);

  useEffect(() => {
    if (gameRef) {
      window.addEventListener("scroll", () => {
        if (window.scrollY > 0) {
          setIsSticky(true);
        } else {
          setIsSticky(false);
        }
        if (window.scrollY + 2 > gameRef.offsetTop) {
          setNavigation("game");
        } else if (window.scrollY > window.innerHeight - 82) {
          setNavigation("company");
        } else {
          setNavigation(null);
        }
      });
    }
  }, [gameRef]);

  return (
    <>
      <header
        className={`cs-site_header cs-style1 text-uppercase ${
          variant ? variant : ""
        } cs-sticky_header ${isSticky ? "cs-sticky_header_active" : ""}`}
      >
        <Div className="cs-main_header">
          <Div className="container">
            <Div className="cs-main_header_in">
              <Div className="cs-main_header_left">
                <Link
                  className="cs-site_branding img-wrapper"
                  to="/"
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  <img src={logo} alt="Logo" />
                </Link>
              </Div>
              <Div className="cs-main_header_right">
                <Div className="cs-nav cs-primary_font cs-medium">
                  <ul
                    className="cs-nav_list"
                    style={{ display: `${mobileToggle ? "block" : "none"}` }}
                  >
                    {/* <li>
                      <NavLink
                        to="/"
                        onClick={() => {
                          setMobileToggle(false);
                          window.scrollTo(0, window.innerHeight - 80);
                        }}
                        className={nevigatgion === "company" ? "current" : ""}
                      >
                        COMPANY
                      </NavLink>
                    </li> */}
                    {/* <li>
                      <NavLink
                        to="/"
                        onClick={() => {
                          setMobileToggle(false);
                          gameRef.scrollIntoView();
                        }}
                        className={nevigatgion === "game" ? "current" : ""}
                      >
                        GAME
                      </NavLink>
                    </li> */}
                  </ul>
                  <span
                    className={
                      mobileToggle
                        ? "cs-munu_toggle cs-toggle_active"
                        : "cs-munu_toggle"
                    }
                    onClick={() => setMobileToggle(!mobileToggle)}
                  >
                    <span></span>
                  </span>
                </Div>
              </Div>
              {/* <Div className="cs-main_header_right">
                <Div className="cs-toolbox">
                  <span
                    className="cs-icon_btn"
                    onClick={() => setSideHeaderToggle(!sideHeaderToggle)}
                  >
                    <span className="cs-icon_btn_in">
                      <span />
                      <span />
                      <span />
                      <span />
                    </span>
                  </span>
                </Div>
              </Div> */}
            </Div>
          </Div>
        </Div>
      </header>

      <Div
        className={
          sideHeaderToggle ? "cs-side_header active" : "cs-side_header"
        }
      >
        <button
          className="cs-close"
          onClick={() => setSideHeaderToggle(!sideHeaderToggle)}
        />
        <Div
          className="cs-side_header_overlay"
          onClick={() => setSideHeaderToggle(!sideHeaderToggle)}
        />
        <Div className="cs-side_header_in">
          <Div className="cs-side_header_shape" />
          <Link className="cs-site_branding" to="/">
            <img src="/images/footer_logo.svg" alt="Logo" />
          </Link>
          <Div className="cs-side_header_box">
            <h2 className="cs-side_header_heading">
              Do you have a project in your <br /> mind? Keep connect us.
            </h2>
          </Div>
          <Div className="cs-side_header_box">
            <ContactInfoWidget title="Contact Us" withIcon />
          </Div>
          <Div className="cs-side_header_box">
            <Newsletter
              title="Subscribe"
              subtitle="At vero eos et accusamus et iusto odio as part dignissimos ducimus qui blandit."
              placeholder="example@gmail.com"
            />
          </Div>
          <Div className="cs-side_header_box">
            <SocialWidget />
          </Div>
        </Div>
      </Div>
    </>
  );
}
