import React, { useState } from "react";
import Button from "../Button";
import Div from "../Div";
import Spacing from "../Spacing";
import orangeLogo from "../../mb-assets//img/logo/orange-logo.png";
import arrow from "../../mb-assets/img/icon/arrow-right.png";

export default function Portfolio2({
  txt,
  imageUrl,
  whiteFont,
  orangeFont,
  reverse,
  line,
  official,
}) {
  const [hovered, setHovered] = useState(false);
  return (
    <Div className="cs-portfolio cs-style2">
      <Div className="cs-gradient_shape" />
      <Div className="container">
        <Div
          className={
            !reverse
              ? "row align-items-center"
              : "row align-items-center reverse"
          }
        >
          <Div className="col-lg-6">
            <Div
              className={
                hovered
                  ? "cs-portfolio_img active austom"
                  : "cs-portfolio_img austom"
              }
            >
              {line && <div className="top-line-absolute"></div>}
              <Div className="cs-portfolio_img_in cs-shine_hover_1 cs-radius_5">
                <div className="phrase">
                  <span>{whiteFont} </span>
                  <span> {orangeFont}</span>
                </div>
                <img src={imageUrl} alt="Portfolio" className="cs-w100" />
              </Div>
            </Div>
          </Div>
          <Div className="col-xl-5 col-lg-6 about-des">
            <Div className="cs-section_heading cs-style1">
              <Spacing lg="0" md="35" />
              <h3 className="cs-section_subtitle">{txt}</h3>
              {official && (
                <div
                  className="to-official"
                  onClick={() => {
                    window.open("https://www.galaxycommanders.io/");
                  }}
                >
                  <div className="img-wrapper">
                    <img src={arrow} alt="" />
                  </div>
                  <p>GALAXY COMMANDERS OFFICIAL SITE</p>
                </div>
              )}

              <Spacing lg="45" md="20" />
            </Div>
          </Div>
        </Div>
      </Div>
    </Div>
  );
}
